import { StyleSheet, View } from "react-native";

import { ColorStyle, Radius, Spacing } from "../../constants";
import { Text } from "../../display";

interface FakeTextInputWithLabelProps {
  label: string;
  value?: string | null | undefined;
}

export const FakeTextInputWithLabel = ({
  label,
  value,
}: FakeTextInputWithLabelProps) => {
  return (
    <View style={styles.container}>
      <Text color="FONT_LOW_EMPHASIS" size="SMALL">
        {label}
      </Text>
      <Text color="FONT_LOW_EMPHASIS" size="BODY">
        {value ?? " "}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: Spacing.S8,
    paddingHorizontal: Spacing.S16,
    gap: Spacing.S4,
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    borderRadius: Radius.SMALL,
  },
});
