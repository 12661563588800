import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Platform, StyleSheet, ViewStyle } from "react-native";

import {
  useAddCallback,
  useBooleanState,
  useCombinedRefs,
} from "@kraaft/helper-hooks";

import { ColorStyle } from "../../../constants/color";
import { FontSize } from "../../../constants/fontSize";
import { StyledNestedText } from "../../../display/styledNestedText";
import { TextInputWithAutoSize } from "../textInputWithAutoSize";
import { TextInputWithAutoSizeHandle } from "../textInputWithAutoSize/textInputWithAutoSize.types";
import {
  TextInputWithMatchersHandle,
  TextInputWithMatchersProps,
} from "./textInputWithMatchers.types";
import { useFirstTextMatch } from "./useFirstMatch";
import { useFirstMatchPressHandler } from "./useFirstMatchPressHandler";

export const TextInputWithMatchers = forwardRef<
  TextInputWithMatchersHandle,
  PropsWithChildren<TextInputWithMatchersProps>
>(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      inputStyle,
      containerStyle,
      editable,
      accessibilityLabel,
      multiline,
      placeholder,
      autoFocus,
      keyboardType,
      returnKeyType,
      returnKeyLabel,
      disableAutocomplete,
      onLayout,
      matchers,
    },
    externalRef,
  ) => {
    const textInputRef = useRef<TextInputWithAutoSizeHandle>(null);
    const [isFocused, setInternalFocus, setInternalBlur] =
      useBooleanState(autoFocus);
    const firstTextMatch = useFirstTextMatch(value ?? "", matchers);

    const overloadedOnFocus = useAddCallback(onFocus, setInternalFocus);
    const overloadedOnBlur = useAddCallback(onBlur, setInternalBlur);

    const handleEffectivePress = useCallback(() => {
      if (textInputRef.current === null) {
        return;
      }

      /** ios focuses the TextInput behind, so we need to blur it (40ms seems to be the sxeet spot) */
      if (Platform.OS === "ios") {
        setTimeout(textInputRef.current.blur, 100);
      }
    }, []);

    const handleWrapperPress = useFirstMatchPressHandler({
      firstTextMatch,
      onEffectivePress: handleEffectivePress,
    });

    const autoSizeWrapperProps = useMemo(
      () => ({
        nativeOnPress: handleWrapperPress,
        nativeShouldCatchPressEvents:
          !isFocused && handleWrapperPress !== undefined,
      }),
      [handleWrapperPress, isFocused],
    );

    const renderedContent = useMemo(
      () =>
        value ? (
          <StyledNestedText
            text={value}
            matchers={matchers}
            style={styles.textInputBase}
            multiline={multiline}
            innerTextProps={{ style: styles.remanentInnerText }}
            suspendInterpolation={isFocused}
          />
        ) : null,
      [isFocused, matchers, multiline, value],
    );

    return (
      <TextInputWithAutoSize
        ref={useCombinedRefs(externalRef, textInputRef)}
        onFocus={overloadedOnFocus}
        onBlur={overloadedOnBlur}
        inputStyle={[styles.textInputBase, inputStyle]}
        containerStyle={containerStyle}
        editable={editable}
        accessibilityLabel={accessibilityLabel}
        multiline={multiline}
        value={Platform.select({ web: value, default: undefined })}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disableAutocomplete={disableAutocomplete}
        keyboardType={keyboardType}
        returnKeyType={returnKeyType}
        returnKeyLabel={returnKeyLabel}
        onChange={onChange}
        autoSizeWrapperProps={autoSizeWrapperProps}
        onLayout={onLayout}
      >
        {renderedContent}
      </TextInputWithAutoSize>
    );
  },
);

const styles = StyleSheet.create({
  textInputBase: {
    fontFamily: "Apercu",
    fontWeight: "400",
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },

  remanentInnerText: Platform.select({
    web: {
      pointerEvents: "none",
    },
    default: {},
  }) as ViewStyle,
});
