import { ReactNode, createContext } from "react";

export type PortalManagementContextType = {
  addElement: (element: ReactNode, key: string, hostname: string) => void;
  removeElement: (key: string, hostname: string) => void;
};

export type PortalDataContextType = Record<string, [string, ReactNode][]>;

export const PortalManagementContext: React.Context<PortalManagementContextType> =
  createContext({
    addElement: (_element, _key, _hostname) => {
      return;
    },
    removeElement: (_key, _hostname) => {
      return;
    },
  });

export const PortalDataContext: React.Context<PortalDataContextType> =
  createContext({});
