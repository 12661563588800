import OneSignal, {
  NotificationClickEvent,
  NotificationForegroundWillDisplayEvent,
} from "react-onesignal";

import { getEnvironment } from "@kraaft/shared/constants/environment/environment.utils";
import { type Notifications } from "@kraaft/shared/core/modules/notifications/notifications";
import { executeAfterStatePropagation } from "@kraaft/ui";

export class OneSignalNotifications implements Notifications {
  private init: Promise<void> | undefined;

  private async ensureInitialized() {
    if (this.init) {
      return this.init;
    }
    this.init = (async () => {
      await OneSignal.init({
        appId: getEnvironment().ONESIGNAL.APP_ID,
        allowLocalhostAsSecureOrigin: __DEV__,
        requiresUserPrivacyConsent: false,
        autoRegister: false,
        autoResubscribe: true,
      });
      await OneSignal.setConsentGiven(true);
      await OneSignal.setConsentRequired(false);
    })();
  }

  initAtAppStartup() {
    this.ensureInitialized().catch(console.error);
  }

  async login(userId: string) {
    await this.ensureInitialized();
    await OneSignal.logout();
    await OneSignal.login(userId);
  }

  async logout() {
    await this.ensureInitialized();
    await OneSignal.logout();
  }

  async setLanguage(language: string) {
    await this.ensureInitialized();
    // Noop on web
  }

  addNotificationListener<T = any>(callback: (body: T) => void) {
    function handler(event: NotificationForegroundWillDisplayEvent) {
      callback(event.notification.additionalData as T);
    }
    OneSignal.Notifications.addEventListener("foregroundWillDisplay", handler);
    return () =>
      OneSignal.Notifications.removeEventListener(
        "foregroundWillDisplay",
        handler,
      );
  }

  addNotificationClickListener<T = any>(callback: (body: T) => void) {
    function handler(event: NotificationClickEvent) {
      callback(event.notification.additionalData as T);
    }

    OneSignal.Notifications.addEventListener("click", handler);
    return () => OneSignal.Notifications.removeEventListener("click", handler);
  }

  addPermissionChangeListener(callback: (allowed: boolean) => void) {
    OneSignal.Notifications.addEventListener("permissionChange", callback);

    executeAfterStatePropagation(() =>
      callback(OneSignal.Notifications.permission),
    );

    return () =>
      OneSignal.Notifications.removeEventListener("permissionChange", callback);
  }
}
