import { makeStyles } from "@mui/styles";
import { RefAttributes, useCallback, useRef } from "react";

import { isNotEmptyString } from "@kraaft/helper-functions";

import {
  DraggableMessageType,
  DropIndicator,
  DroppableItem,
  useDroppable,
} from "../droppable";
import { TextInput } from "../textInput";
import {
  RequiredTextInputComponentHandle,
  RequiredTextInputComponentProps,
  TextInputHandle,
  WithTextInputComponent,
} from "../textInput/textInput.types";
import { DroppableInputProps } from "./droppableInput.types";

export const DroppableInput = <
  P extends RequiredTextInputComponentProps &
    RefAttributes<RequiredTextInputComponentHandle>,
>({
  onDropText,
  translate,
  ...inputProps
}: DroppableInputProps &
  WithTextInputComponent<
    RequiredTextInputComponentProps,
    RequiredTextInputComponentHandle,
    P
  >) => {
  const classes = useStyles();

  const inputRef = useRef<TextInputHandle>(null);

  const handleDrop = useCallback(
    (item: DroppableItem) => {
      if (inputProps.disabled) {
        return;
      }

      const text = item.renderText?.();
      if (isNotEmptyString(text)) {
        const origin = inputProps.value?.trim() ?? "";
        const sep = inputProps.multiline ? "\n" : " ";
        const newText = `${origin}${sep}${text}`.trim();
        inputProps.onChange?.(newText);
        onDropText?.(text);

        inputRef.current?.focus();
      }
    },
    [inputProps, onDropText],
  );

  const [droppableContainerRef, { canDrop, isOver }] = useDroppable({
    acceptType: DraggableMessageType.MessageText,
    acceptSystemFile: false,
    onDrop: handleDrop,
  });

  return (
    <div ref={droppableContainerRef} className={classes.container}>
      <TextInput
        ref={inputRef}
        TextInputComponent={inputProps.TextInputComponent as any}
        extraTextInputProps={inputProps.extraTextInputProps as any}
        nativeID={inputProps.nativeID}
        accessibilityLabel={inputProps.accessibilityLabel}
        multiline={inputProps.multiline}
        disabled={inputProps.disabled}
        value={inputProps.value}
        placeholder={inputProps.placeholder}
        autoFocus={inputProps.autoFocus}
        disableAutocomplete={inputProps.disableAutocomplete}
        inputStyle={inputProps.inputStyle}
        containerStyle={inputProps.containerStyle}
        returnKeyType={inputProps.returnKeyType}
        returnKeyLabel={inputProps.returnKeyLabel}
        status={inputProps.status}
        withHelperTextPadding={inputProps.withHelperTextPadding}
        helperText={inputProps.helperText}
        onChange={inputProps.onChange}
        onBlur={inputProps.onBlur}
      />
      {canDrop && !inputProps.disabled && (
        <DropIndicator isActive={isOver} text={translate("depositHere")} />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    position: "relative",
  },
});
