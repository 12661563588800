import { Platform, PlatformOSType } from "react-native";
import { assert } from "ts-essentials";

import { anchoredSheetDefinition } from "./anchoredSheet/anchoredSheet.definition";
import { bottomSheetDefinition } from "./bottomSheet/bottomSheet.definition";
import { popupSheetDefinition } from "./popupSheet/popupSheet.definition";
import { SheetDefinition } from "./sheet.types";

const sheetFactory = {
  bottom: bottomSheetDefinition,
  anchored: anchoredSheetDefinition,
  popup: popupSheetDefinition,
} satisfies { [variant: string]: SheetDefinition };

type AvailableVariantForPlatform = {
  native: "bottom";
  ios: "bottom";
  android: "bottom";
  web: "anchored" | "popup";
} & { [P in PlatformOSType]: SheetVariant };

type SheetFactoryType = typeof sheetFactory;
type SheetVariant = keyof SheetFactoryType;

type Specifics = { [P in PlatformOSType]?: AvailableVariantForPlatform[P] } & {
  default?: SheetVariant;
};

type ExtractVariant<S extends Specifics> = Extract<S[keyof S], SheetVariant>;

export function getSheet<S extends Specifics>(
  specifics: S,
): SheetFactoryType[ExtractVariant<S>] {
  const variantForPlatform = Platform.select(specifics);

  if (variantForPlatform !== undefined) {
    return sheetFactory[variantForPlatform as ExtractVariant<S>];
  }

  assert(false, "sheet variant is not defined for platform");
}
