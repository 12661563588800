import { Image, Keyboard, Platform } from "react-native";
export function getImageSize(uri: string): Promise<[number, number]> {
  return new Promise((resolve, reject) => {
    const success = (width: number, height: number) => resolve([width, height]);
    Image.getSize(uri, success, reject);
  });
}

/**
 * Detect if app is currently running in a mobile platform.
 */
export function isNative(): boolean {
  return ["ios", "android"].includes(Platform.OS);
}

export function dismissNativeKeyboard() {
  if (isNative()) {
    Keyboard.dismiss();
  }
}
