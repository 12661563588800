import { Ref, RefAttributes, useCallback, useMemo, useRef } from "react";
import {
  Animated,
  Easing,
  type GestureResponderEvent,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from "react-native";

import {
  useAddCallback,
  useBooleanState,
  useCombinedRefs,
} from "@kraaft/helper-hooks";
import { ExtractRefHandle } from "@kraaft/helper-types";
import { betterForwardRef } from "@kraaft/shared/core/utils/betterForwardRef";

import { Color, ColorStyle } from "../../constants/color";
import { FontSize } from "../../constants/fontSize";
import { PixelSize } from "../../constants/pixelSize";
import { Radius } from "../../constants/radius";
import { Spacing } from "../../constants/spacing";
import { Icon } from "../../display/icon";
import { IconName } from "../../display/icon/icon.types";
import { Text } from "../../display/text";
import { getNativeDriver } from "../../utils/animation.utils";
import { BaseTextInputComponent } from "../textInputComponents";
import {
  RequiredTextInputComponentHandle,
  RequiredTextInputComponentProps,
  TextInputHandle,
  TextInputProps,
  WithTextInputComponent,
} from "./textInput.types";

function getBorderColorFromStatus(status: TextInputProps["status"]) {
  if (status === "validated") {
    return Color.GREEN_LAGOON;
  }
  if (status === "erroneous") {
    return ColorStyle.ACTION_DESCTRUCTIVE_DISABLED;
  }
  return ColorStyle.SEPARATOR;
}

function getHelperTextColorFromStatus(status: TextInputProps["status"]) {
  if (status === "validated") {
    return Color.GREEN_LAGOON;
  }
  if (status === "erroneous") {
    return ColorStyle.ACTION_DESCTRUCTIVE;
  }
  return ColorStyle.FONT_LOW_EMPHASIS;
}

const ANIMATION_TIME = 200;
const ANIMATION_EASING = Easing.inOut(Easing.ease);

export const TextInput = betterForwardRef(
  <
    P extends RequiredTextInputComponentProps &
      RefAttributes<RequiredTextInputComponentHandle>,
  >(
    {
      TextInputComponent: _TextInputComponent,
      disabled,
      status,
      helperText,
      withHelperTextPadding,
      nativeID,
      value,
      onChange,
      onFocus,
      onBlur,
      inputStyle,
      containerStyle: inputContainerStyle,
      accessibilityLabel,
      multiline,
      placeholder,
      autoFocus,
      keyboardType,
      returnKeyType,
      returnKeyLabel,
      disableAutocomplete,
      extraTextInputProps,
    }: TextInputProps &
      WithTextInputComponent<
        RequiredTextInputComponentProps,
        RequiredTextInputComponentHandle,
        P
      >,
    externalRef: Ref<TextInputHandle>,
  ) => {
    const textInputRef = useRef<ExtractRefHandle<P>>(null);
    const [isFocused, setInternalFocus, setInternalBlur] =
      useBooleanState(autoFocus);

    const borderColorAnimatedValue = useRef(
      new Animated.Value(autoFocus ? 1 : 0),
    ).current;

    const handleIconPress = useCallback((event: GestureResponderEvent) => {
      event.preventDefault();

      if (textInputRef.current?.isFocused()) {
        textInputRef.current?.blur();
      } else {
        textInputRef.current?.focus();
      }
    }, []);

    const animateBorderColor = useCallback(
      (toValue: 1 | 0) => {
        Animated.timing(borderColorAnimatedValue, {
          toValue,
          duration: ANIMATION_TIME,
          easing: ANIMATION_EASING,
          ...getNativeDriver(false),
        }).start();
      },
      [borderColorAnimatedValue],
    );

    const overloadedOnFocus = useAddCallback(
      onFocus,
      useCallback(() => {
        setInternalFocus();
        animateBorderColor(!disabled ? 1 : 0);
      }, [animateBorderColor, disabled, setInternalFocus]),
    );
    const overloadedOnBlur = useAddCallback(
      onBlur,
      useCallback(() => {
        setInternalBlur();
        animateBorderColor(0);
      }, [animateBorderColor, setInternalBlur]),
    );

    const borderColor = borderColorAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [getBorderColorFromStatus(status), ColorStyle.ACTION_CTA],
    });

    const helperTextColor = getHelperTextColorFromStatus(status);

    const containerStyle = useMemo(
      () =>
        StyleSheet.flatten([
          styles.container,
          disabled && styles.containerDisabled,
          { borderColor },
        ]),
      [borderColor, disabled],
    );

    const helperTextStyle = useMemo(
      () => StyleSheet.flatten([styles.helperText, { color: helperTextColor }]),
      [helperTextColor],
    );

    const iconName: IconName = isFocused ? "check" : "edit-02";
    const iconColor = isFocused
      ? ColorStyle.ACTION_CTA
      : getBorderColorFromStatus(status);

    const TextInputComponent =
      _TextInputComponent ?? BaseTextInputComponent.TextInputWithAutoSize;

    return (
      <>
        <Animated.View accessibilityLabel="input" style={containerStyle}>
          <TextInputComponent
            ref={useCombinedRefs(externalRef, textInputRef)}
            {...(nativeID ? { nativeID: `${nativeID}-input` } : {})}
            onFocus={overloadedOnFocus}
            onBlur={overloadedOnBlur}
            inputStyle={[styles.input, inputStyle]}
            containerStyle={[styles.inputContainer, inputContainerStyle]}
            editable={!disabled}
            accessibilityLabel={accessibilityLabel}
            multiline={multiline}
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disableAutocomplete={disableAutocomplete}
            keyboardType={keyboardType}
            returnKeyType={returnKeyType}
            returnKeyLabel={returnKeyLabel}
            onChange={onChange}
            {...(extraTextInputProps as any)}
          />
          {!disabled ? (
            <Pressable
              accessibilityLabel="input-edit"
              style={styles.iconContainer}
              {...{
                [Platform.select({ web: "onMouseDown", default: "onPress" })]:
                  handleIconPress,
              }}
              focusable={false}
            >
              <Icon name={iconName} size="MEDIUM" color={iconColor} />
            </Pressable>
          ) : null}
        </Animated.View>
        {(withHelperTextPadding || helperText) && (
          <View style={styles.helperTextContainer}>
            {helperText ? (
              <Text style={helperTextStyle} numberOfLines={3}>
                {helperText}
              </Text>
            ) : null}
          </View>
        )}
      </>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: Radius.SMALL,
    overflow: "hidden",
  },
  containerDisabled: {
    backgroundColor: ColorStyle.BACKGROUND_DISABLED,
  },
  input: {
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S8,
    paddingTop: 14,
    paddingBottom: 14,
  },

  inputContainer: {
    flexGrow: 1,
    flexShrink: 1,
    minHeight: PixelSize.S48,
  },
  iconContainer: {
    flexShrink: 0,
    paddingVertical: Spacing.S8,
    paddingLeft: Spacing.S8,
    paddingRight: Spacing.S16,
  },
  helperTextContainer: {
    minHeight: PixelSize.S16,
    marginTop: Spacing.S4,
    marginLeft: Spacing.S4,
  },
  helperText: {
    fontSize: FontSize.SMALL,
  },
});
