import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { makeStyles } from "@mui/styles";
import { clsx } from "clsx";
import { useCallback } from "react";

import { Spacing } from "../../../constants";
import { PopupSheetDefinition } from "./popupSheet.definition/popupSheet.definition.types";

const centeredPopupStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const ANIMATION_DURATION = 150;

export const PopupSize = {
  MEDIUM: 960,
  SMALL: 500,
};

export const PopupSheetHost: PopupSheetDefinition["Host"] = ({
  id = "ide2e-popup",
  children,
  open,
  size,
  onClose,
}) => {
  const classes = useStyles();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose],
  );

  const nodeId = useFloatingNodeId();
  const { context, refs, floatingStyles } = useFloating({
    open,
    nodeId,
    onOpenChange: handleOpenChange,
  });

  const dismiss = useDismiss(context, {
    bubbles: false, // allows the sheet to be dismissed without dismissing the parent. Ex: database filter menu
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: ANIMATION_DURATION,
    initial: {
      transform: "scale(0.75)",
      opacity: 0,
    },
    common: {
      transformOrigin: "center",
      transform: "scale(1)",
      opacity: 1,
    },
  });

  return (
    <FloatingNode id={nodeId}>
      {isMounted ? (
        <FloatingPortal>
          <FloatingOverlay
            className={clsx(
              classes.overlay,
              open ? classes.overlayFadeIn : classes.overlayFadeOut,
            )}
            lockScroll
          >
            <FloatingFocusManager
              context={context}
              closeOnFocusOut={true}
              visuallyHiddenDismiss
            >
              <div
                style={{ ...floatingStyles, ...centeredPopupStyle }}
                ref={refs.setFloating}
                {...getFloatingProps()}
              >
                <div
                  style={{
                    ...styles,
                    width: PopupSize[size],
                    maxWidth: `calc(100vw - ${Spacing.S24}px)`,
                  }}
                  id={id}
                >
                  {children}
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      ) : null}
    </FloatingNode>
  );
};

const useStyles = makeStyles({
  overlay: {
    zIndex: 1500, // above legacy dialogs
    display: "flex",
    backgroundColor: "rgba(0,0,0,.25)",
  },

  overlayFadeIn: {
    animation: "$fadeIn",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  overlayFadeOut: {
    animation: "$fadeOut",
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationFillMode: "forwards",
    animationTimingFunction: "ease",
  },

  "@global": {
    "@keyframes fadeIn": {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    "@keyframes fadeOut": {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
    },
  },
});
