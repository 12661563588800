import { Platform } from "react-native";

import environment from "./environment.json";

const LOCALHOST = Platform.select({
  android: "10.0.2.2",
  ios: "192.168.1.153",
  default: "localhost",
});

function replaceLocalhost(url: string) {
  return url.replace("${LOCALHOST}", LOCALHOST);
}

export function getEnvironment() {
  return environment;
}

export function getAppEngineApiBaseUrl() {
  return replaceLocalhost(getEnvironment().APP_ENGINE_API_BASE_URL);
}

export function getHostEndpoint() {
  return replaceLocalhost(getEnvironment().FIREBASE.EMULATOR.HOST_ENDPOINT);
}

export function getGoogleGeocodeApiKey() {
  return Platform.select({
    web: getEnvironment().GOOGLE.WEB_ONLY_GEOCODE_API_KEY,
    default: getEnvironment().GOOGLE.NATIVE_ONLY_GEOCODE_KEY,
  });
}
