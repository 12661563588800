import { type PropsWithChildren, useMemo } from "react";
import { Text as RNText, type TextStyle } from "react-native";

import { getColorFromColorValue, getFontSizeFromSizeValue } from "../../utils";
import { TextProps } from "./text.types";
import { getFontFamilyFromWeight } from "./text.utils";

export const Text = ({
  id,
  children,
  weight = "regular",
  color = "FONT_HIGH_EMPHASIS",
  size,
  numberOfLines,
  ellipsizeMode,
  selectable,
  style,
  onPress,
  allowFontScaling,
  onLayout,
}: PropsWithChildren<TextProps>) => {
  const textStyle = useMemo(() => {
    const _textStyle: TextStyle = {};

    if (weight !== undefined) {
      _textStyle.fontFamily = getFontFamilyFromWeight(weight);
    }
    if (color !== undefined) {
      _textStyle.color = getColorFromColorValue(color);
    }
    if (size !== undefined) {
      _textStyle.fontSize = getFontSizeFromSizeValue(size);
    }

    return _textStyle;
  }, [weight, color, size]);

  return (
    <RNText
      nativeID={id}
      style={[textStyle, style]}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      selectable={selectable}
      onPress={onPress}
      allowFontScaling={allowFontScaling}
      onLayout={onLayout}
    >
      {children}
    </RNText>
  );
};
